import { saveMangaTitleInStorage } from '../mixins/utils';

// modules/app mutations
const setSiteSettings = (state, payload) => {
  state.app.s3PublicURL = payload.s3PublicURL;
  state.app.s3TemporaryURL = payload.s3TemporaryURL;
  state.app.genreURL = payload.genreURL;
  state.app.peopleURL = payload.peopleURL;
  state.app.profileURL = payload.profileURL;
  state.app.mangaURL = payload.mangaURL;
  state.app.localS3Prefix = payload.localS3Prefix;
  // Settings
  state.app.maxNoOfImages = payload.maxNoOfImages;
  state.app.maxRequestTimeout = payload.maxRequestTimeout;
  state.app.maxImageSizeInBytes = payload.maxImageSizeInBytes;

  // Permission codes
  if (payload.accessCodes) {
    for (const code of payload.accessCodes) {
      state.app.accessCodes[code.name] = code.value;
    }
  }

  if (payload.resourceCodes) {
    for (const code of payload.resourceCodes) {
      state.app.resourceCodes[code] = (codes = []) => {
        let allowedCode = 0;
        for (const accessCode of codes) {
          allowedCode = allowedCode | accessCode;
        }
        return {
          resource: code,
          accessCode: allowedCode,
        };
      };
    }
  }
};

const setToken = (state, token) => {
  if (!token) {
    localStorage.removeItem('token');
  }
  if (state.app.user) {
    state.app.user.token = token;
  } else {
    state.app.user = { token };
  }
  localStorage.setItem('token', token);
};

const setUser = (state, user) => {
  const permissions = {};
  for (const p of user.permissions) {
    permissions[p.resourceCode] = p.accessCode;
  }

  user.permissions = permissions;

  if (state.app.user) {
    state.app.user = { ...state.app.user, ...user };
  } else {
    state.app.user = user;
  }
};

const setLoading = (state, payload) => {
  state.app.isLoading = payload;
};

const setBackgroundLoading = (state, payload) => {
  state.app.backgroundLoading = payload;
};

// modules/manga mutations
const setSelectedManga = (state, payload) => {
  state.manga.selectedManga.id = payload.id;
  state.manga.selectedManga.title = payload.title;
  state.manga.selectedManga.mode = payload.mode;

  // Save title in hashmap
  saveMangaTitleInStorage(payload.id, payload.title);
};

const setMangaSavedState = (state, val) => {
  if (state.manga.selectedManga.mode !== 'view') {
    state.manga.selectedManga.isSaved = val;
  }
};

const setSelectedChapter = (state, payload) => {
  state.manga.selectedChapter.id = payload.id;
  if (state.manga.selectedManga.mode !== 'view') {
    state.manga.selectedChapter.mode = payload.mode;
  }
};

const setChapterSavedState = (state, val) => {
  if (state.manga.selectedManga.mode !== 'view') {
    state.manga.selectedChapter.isSaved = val;
  }
};

const setChapterPagesSavedState = (state, val) => {
  if (state.manga.selectedChapter.mode !== 'view') {
    state.manga.selectedChapter.pagesSaved = val;
  }
};

const setNewChapter = (state, chapter) => {
  if (state.manga.selectedManga.mode !== 'view') {
    state.manga.newChapter = chapter;
  }
};

const setSelectedPages = (state, pages) => {
  if (state.manga.selectedChapter.mode === 'edit') {
    state.manga.selectedPages = pages;
  }
};

const pushToSelectedPages = (state, page) => {
  if (state.manga.selectedChapter.mode === 'edit') {
    state.manga.selectedPages = [...state.manga.selectedPages, page];
  }
};

const removeFromSelectedPages = (state, page) => {
  if (state.manga.selectedChapter.mode === 'edit') {
    const newSelectedPages = [...state.manga.selectedPages].filter(
      e => e !== page
    );
    state.manga.selectedPages = newSelectedPages;
  }
};

export {
  setSiteSettings,
  setToken,
  setUser,
  setLoading,
  setBackgroundLoading,
  setSelectedManga,
  setMangaSavedState,
  setSelectedChapter,
  setChapterSavedState,
  setChapterPagesSavedState,
  setNewChapter,
  setSelectedPages,
  pushToSelectedPages,
  removeFromSelectedPages,
};
