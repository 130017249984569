// Getters from modules/app.js
const config = state => state.app.config;
const palette = state => state.app.config.palette;
const isLoading = state => state.app.isLoading;
const backgroundLoading = state => state.app.backgroundLoading;
const currentPage = state => state.app.currentPage;

// Access and resource codes
const ACCESS_CODES = state => state.app.accessCodes;
const RESOURCE_CODES = state => state.app.resourceCodes;

const user = state => state.app.user;
const token = state => (state.app.user ? state.app.user.token : null);

// All public urls
const s3PublicURL = state => state.app.s3PublicURL;
const s3TemporaryURL = state => state.app.s3TemporaryURL;
const localS3Prefix = state => state.app.localS3Prefix;
const genreURL = state => state.app.genreURL;
const peopleURL = state => state.app.peopleURL;
const profileURL = state => state.app.profileURL;
const mangaURL = state => state.app.mangaURL;
const maxImageSizeInBytes = state => state.app.maxImageSizeInBytes;
const maxNoOfImages = state => state.app.maxNoOfImages;
const maxRequestTimeout = state => state.app.maxRequestTimeout;

// Getters from modules/manga.js
const selectedMangaId = state => state.manga.selectedManga.id;
const selectedMangaMode = state => state.manga.selectedManga.mode;
const selectedMangaTitle = state => state.manga.selectedManga.title;
const isMangaSaved = state => state.manga.selectedManga.isSaved;
const selectedChapterId = state => state.manga.selectedChapter.id;
const selectedChapterMode = state => state.manga.selectedChapter.mode;
const isChapterSaved = state => state.manga.selectedChapter.isSaved;
const isPagesSaved = state => state.manga.selectedChapter.pagesSaved;
const baseNewChapter = state => state.manga.newChapter;
const selectedPages = state => state.manga.selectedPages;

export {
  config,
  palette,
  isLoading,
  ACCESS_CODES,
  RESOURCE_CODES,
  backgroundLoading,
  currentPage,
  user,
  token,
  s3PublicURL,
  s3TemporaryURL,
  localS3Prefix,
  genreURL,
  peopleURL,
  mangaURL,
  profileURL,
  maxImageSizeInBytes,
  maxNoOfImages,
  maxRequestTimeout,
  selectedMangaId,
  selectedMangaTitle,
  selectedMangaMode,
  isMangaSaved,
  selectedChapterId,
  selectedChapterMode,
  isChapterSaved,
  isPagesSaved,
  baseNewChapter,
  selectedPages,
};
