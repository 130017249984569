import { QUERIES, MUTATIONS } from '../schemas/auth';
import { deAuthApolloClient } from '../index';

export const login = async(email, password) => {
  const { data } = await deAuthApolloClient.query({
    query: QUERIES.LOGIN,
    variables: { email, password },
  });
  return data;
};

export const logout = async token => {
  const { data } = await deAuthApolloClient.query({
    query: QUERIES.LOGOUT,
    variables: { token },
  });
  return data;
};

export const checkToken = async token => {
  const { data } = await deAuthApolloClient.query({
    query: QUERIES.CHECK_TOKEN,
    variables: { token },
  });
  return data;
};

export const forgetPassword = async useremail => {
  const { data } = await deAuthApolloClient.query({
    query: QUERIES.FORGET_PASSWORD,
    variables: { email: useremail },
  });
  return data;
};

export const resetPassword = async(token, password) => {
  const { data } = await deAuthApolloClient.mutate({
    mutation: MUTATIONS.RESET_PASSWORD,
    variables: { token, password },
  });
  return data;
};
