import { fetchSettings } from '../apollo/api/siteSettings';
import { checkToken } from '../apollo/api/auth';

const loadSiteSettings = async context => {
  context.commit('setLoading', true);
  try {
    const { siteSettings } = await fetchSettings();
    context.commit('setSiteSettings', siteSettings);
  } catch (e) {
    console.log(e);
  }
  context.commit('setLoading', false);
};

const loadAuthToken = async context => {
  context.commit('setLoading', true);
  const token = localStorage.getItem('token');
  if (!!token && token.length && token !== 'null') {
    try {
      const response = await checkToken(token);
      context.commit('setToken', token);
      context.commit('setUser', { ...response.checkToken });
    } catch (e) {
      console.log(e);
    }
  }
  context.commit('setLoading', false);
};

export { loadSiteSettings, loadAuthToken };
