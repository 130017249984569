import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

import { InMemoryCache } from 'apollo-cache-inmemory';
import Vue from 'vue';
import VueApollo from 'vue-apollo';

import { onError } from '@apollo/client/link/error';
import router, { ALLOWED_ROUTES } from '../router';

Vue.use(VueApollo);

// HTTP connection to the API
const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_SERVER,
  credentials: 'include',
});

// Http link for login, forget password and reset password
const LOGIN_GRAPHQL_URL = `${process.env.VUE_APP_GRAPHQL_SERVER}-login`;
const deAuthLink = createUploadLink({
  // You should use an absolute URL here
  uri: LOGIN_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  if (token) {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        auth: token || '',
      },
    };
  }

  return {
    headers,
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const currentRouteName = router.history.current.name;
  const isAllowed = ALLOWED_ROUTES.find(e => e === currentRouteName);

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path[0]}`);
      if (
        message.toLowerCase().search('invalid login token') !== -1 &&
        !isAllowed
      ) {
        window.location.href = '/auth/logout';
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  // link: authLink.concat(httpLink), // Use this when facing graphql error
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

// Login url apolloClient
export const deAuthApolloClient = new ApolloClient({
  link: deAuthLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default new VueApollo({
  defaultClient: apolloClient,
});
