import gql from 'graphql-tag';

export const QUERIES = {
  LOGIN: gql`
    query LOGIN($email: String, $password: String) {
      login(email: $email, password: $password) {
        id
        jwtToken
        name
        email
        permissions {
          resourceCode
          accessCode
        }
      }
    }
  `,
  CHECK_TOKEN: gql`
    query CHECK_TOKEN($token: String) {
      checkToken(token: $token) {
        id
        jwtToken
        name
        email
        permissions {
          resourceCode
          accessCode
        }
      }
    }
  `,
  LOGOUT: gql`
    query LOGOUT($token: String) {
      logout(token: $token) {
        response
      }
    }
  `,
  FORGET_PASSWORD: gql`
    query FORGET_PASSWORD($email: String) {
      forgetPassword(email: $email) {
        response
      }
    }
  `,
};

export const MUTATIONS = {
  RESET_PASSWORD: gql`
    mutation RESET_PASSWORD($token: String, $password: String) {
      resetPassword(token: $token, password: $password) {
        response
      }
    }
  `,
};
