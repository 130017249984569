var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"app-topbar",style:(_vm.computedStyles)},[_c('ul',{staticClass:"app-topbar__menu"},[_vm._l((_vm.items),function(item,key){return [(item.children)?_c('app-topbar-link-group',{key:key,staticClass:"app-topbar__menu-group",attrs:{"is-active":_vm.hasActiveByDefault(item),"icon":[
          'sidebar-menu-item-icon vuestic-iconset',
          item.meta.iconClass ],"title":_vm.$t(item.displayName),"is-multi-row":item.children.length > 10}},_vm._l((item.children),function(subMenuItem,key){return _c('app-topbar-link-group-item',{key:key,staticClass:"app-topbar__menu-group-item",class:{
            'app-topbar__menu-group-item--multi-row':
              item.children.length > 10,
          },attrs:{"to":{ name: subMenuItem.name },"is-active":subMenuItem.name === _vm.$route.name,"title":_vm.$t(subMenuItem.displayName)}})}),1):_c('app-topbar-link',{key:key,staticClass:"app-topbar__link",attrs:{"is-active":item.name === _vm.$route.name,"icon":[
          'sidebar-menu-item-icon vuestic-iconset',
          item.meta.iconClass ],"to":{ name: item.name }}},[_vm._v(" "+_vm._s(_vm.$t(item.displayName))+" ")])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }