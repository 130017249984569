import store from '../store';
import { MANGAS_HASHMAP } from '../constants/localStorageKeys';

export const setTitle = title => {
  document.title = title;
};

export const isAllowed = (requiredCodes = []) => {
  const PERMISSIONS = store.getters.user.permissions;

  for (const requiredCode of requiredCodes) {
    if (
      !(requiredCode.resource in PERMISSIONS) ||
      (PERMISSIONS[requiredCode.resource] & requiredCode.accessCode) !==
        requiredCode.accessCode
    ) {
      return false;
    }
  }
  return true;
};

export const saveMangaTitleInStorage = (mangaId, mangaTitle) => {
  let mangasHashmapStr = localStorage.getItem(MANGAS_HASHMAP);
  const mangasHashmap = !mangasHashmapStr ? {} : JSON.parse(mangasHashmapStr);
  mangasHashmap[mangaId] = mangaTitle;
  localStorage.setItem(MANGAS_HASHMAP, JSON.stringify(mangasHashmap));
};

export const getMangaTitleFromStorage = mangaId => {
  let mangasHashmapStr = localStorage.getItem(MANGAS_HASHMAP);
  const mangasHashmap = !mangasHashmapStr ? {} : JSON.parse(mangasHashmapStr);
  return mangaId in mangasHashmap ? mangasHashmap[mangaId] : '';
};
