import store from '../../../store/index';

export const generateNavigationRoutes = () => {
  const USER_PERMISSIONS = store.getters.user.permissions;
  const MAIN_ROUTE = {
    root: {
      name: '/',
      displayName: 'menu.settings',
    },
    routes: [],
  };

  // Check for dashboard resource and add it
  if (USER_PERMISSIONS.DASHBOARD) {
    MAIN_ROUTE.routes.push({
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      },
    });
  }

  if (USER_PERMISSIONS.MANGA) {
    MAIN_ROUTE.routes.push({
      name: 'base-manga',
      displayName: 'menu.mangas',
      meta: {
        iconClass: 'fa fa-book',
      },
    });
  }

  if (USER_PERMISSIONS.READER) {
    MAIN_ROUTE.routes.push({
      name: 'users',
      displayName: 'menu.users',
      meta: {
        iconClass: 'fa fa-users',
      },
    });
  }

  if (USER_PERMISSIONS.PEOPLE) {
    MAIN_ROUTE.routes.push({
      name: 'authors',
      displayName: 'menu.authors',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-user',
      },
    });
  }

  if (USER_PERMISSIONS.GENRE) {
    MAIN_ROUTE.routes.push({
      name: 'genres',
      displayName: 'menu.genres',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-ui-elements',
      },
    });
  }

  if (USER_PERMISSIONS.SCRAPER) {
    MAIN_ROUTE.routes.push({
      name: 'scrapers',
      displayName: 'menu.scrapers',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-graph',
      },
    });
  }

  if (USER_PERMISSIONS.APP_RELEASE) {
    MAIN_ROUTE.routes.push({
      name: 'app-releases',
      displayName: 'menu.appReleases',
      meta: {
        iconClass: 'fa fa-code-fork',
      },
    });
  }

  // {
  //   name: 'discord-bot',
  //   displayName: 'menu.discordBot',
  //   meta: {
  //     iconClass: 'fa fa-drivers-license',
  //   },
  // },

  const settingRoute = {
    name: 'settings',
    displayName: 'menu.settings',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-settings',
    },
    children: [
      {
        name: 'account',
        displayName: 'Account',
      },
    ],
  };
  if (USER_PERMISSIONS.USER) {
    settingRoute.children.push({
      name: 'admin-users',
      displayName: 'Users',
    });
  }

  if (USER_PERMISSIONS.ROLE) {
    settingRoute.children.push({
      name: 'roles',
      displayName: 'Roles',
    });
  }

  MAIN_ROUTE.routes.push(settingRoute);

  MAIN_ROUTE.root.displayName = MAIN_ROUTE.routes[0].displayName;

  return MAIN_ROUTE;
};
