const state = {
  sidebar: {
    opened: false,
  },
  config: {
    palette: {
      primary: '#4ae387',
      danger: '#e34a4a',
      info: '#4ab2e3',
      success: '#db76df',
      warning: '#f7cc36',
      white: '#fff',
      black: '#000',
      fontColor: '#34495e',
      transparent: 'transparent',
      lighterGray: '#ddd',
    },
  },
  // User config
  user: null,
  isLoading: true,
  backgroundLoading: false,
  currentPage: 'dashboard',
  // Public urls
  s3PublicURL: '',
  s3TemporaryURL: '',
  localS3Prefix: '',
  mangaURL: '',
  genreURL: '',
  peopleURL: '',
  profileURL: '',
  // Settings
  maxNoOfImages: 10,
  maxRequestTimeout: 5000,
  maxImageSizeInBytes: 8388608,
  // Permission codes
  accessCodes: {},
  resourceCodes: {},
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setBackgroundLoading(state, isLoading) {
    state.backgroundLoading = isLoading;
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
};

const actions = {};

export default {
  state,
  mutations,
  actions,
};
