// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import { ColorThemePlugin } from '../vuestic-ui';
import store from '../store/index';
import router from '../router/index';
import apolloProvider from '../apollo';
import { VuesticPlugin } from '../vuestic-ui/components';
import '../i18n/index';
import YmapPlugin from 'vue-yandex-maps';
import VueClipboard from 'vue-clipboard2';
import VueMoment from 'vue-moment';
import VJstree from 'vue-jstree';

import '../registerServiceWorker';

import { consoleBuildInfo } from 'vue-cli-plugin-build-info/plugin';

consoleBuildInfo();

Vue.use(VuesticPlugin);
Vue.use(YmapPlugin);
Vue.use(VueClipboard);
Vue.use(VueMoment);
Vue.use(VJstree);

Vue.use(ColorThemePlugin, {});

// Load site settings first
store.dispatch('loadSiteSettings');

// Load token and login
store.dispatch('loadAuthToken');

router.beforeEach((to, from, next) => {
  store.commit('setLoading', true);
  next();
});

router.afterEach((to, from) => {
  store.commit('setCurrentPage', to.name);
  store.commit('setLoading', false);
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  apolloProvider,
  render: h => h(App),
});
