import gql from 'graphql-tag';

export const QUERIES = {
  SETTINGS: gql`
    query FETCH_SETTINGS {
      siteSettings {
        genreURL
        profileURL
        peopleURL
        s3TemporaryURL
        s3PublicURL
        localS3Prefix
        mangaURL
        maxImageSizeInBytes
        maxNoOfImages
        maxRequestTimeout

        accessCodes {
          name
          value
        }
        resourceCodes
      }
    }
  `,
};
