<template>
  <div class="auth-layout row align-content--center">
    <div class="flex xs12 pa-3 flex-center">
      <router-link class="py-5 flex-center" to="/">
        <p class="display-1">{{ $t('navbar.title') }}</p>
      </router-link>
    </div>

    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-tabs v-model="tabIndex" center>
            <va-tab>{{ $t('auth.login') }}</va-tab>
            <va-tab>{{ $t('auth.recover_password') }}</va-tab>
          </va-tabs>

          <va-separator />

          <div class="pa-3">
            <router-view />
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const tabs = ['login', 'recover-password'];

export default {
  name: 'AuthLayout',
  data() {
    return {
      selectedTabIndex: 0,
      tabTitles: ['login', 'recover-password'],
    };
  },
  computed: {
    tabIndex: {
      set(tabIndex) {
        this.$router.push({ name: tabs[tabIndex] });
      },
      get() {
        return tabs.indexOf(this.$route.name);
      },
    },
    ...mapGetters(['token', 'user']),
  },
  created() {
    if (this.token && this.user) {
      this.$router.push({ name: 'dashboard' });
    } else if (this.token) {
      console.log('Clearing out token');
      this.setToken(null);
    }
  },
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(to right, #e3b338, #f2cf4c);

  &__card {
    width: 100%;
    max-width: 600px;
  }

  &__options {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
}
</style>
